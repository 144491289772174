import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 1000px;

  .inner {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 300px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const StyledContactForm = styled.div`
  width: 400px;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type='submit'] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(49, 78, 232);
      color: white;
      border: none;
    }
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const [submitted, setSubmitted] = useState(false);

  const form = useRef();

  const sendEmail = e => {
    e.preventDefault();

    emailjs.sendForm('service_kj8dbie', 'template_4dvk24d', form.current, '8Rz_7yGj7IxzTwsfn').then(
      result => {
        console.log(result.text);
        console.log('message sent');
        setSubmitted(true);
      },
      error => {
        console.log(error.text);
      },
    );
  };
  if (submitted) {
    return (
      <StyledAboutSection id="about" ref={revealContainer}>
        <h2 className="numbered-heading">Wanna talk?</h2>
        <div className="inner">
          <StyledText>
            <div className="big-heading">Thank you!</div>
            <div className="medium-heading">We'll be in touch soon.</div>
            <div className="small-heading">
              If you have any further queries, contact me at <i>khubaibalam2000@gmail.com</i>
            </div>
          </StyledText>
          <iframe
            src="https://my.spline.design/manwritingletter-2cad42b6a59498cd7fbe2cc513373af2/"
            title="letter"
            frameBorder="0"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </StyledAboutSection>
    );
  }

  return (
    <StyledAboutSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading">Wanna talk?</h2>

      <div className="inner">
        <StyledText>
          <div>
            <StyledContactForm>
              <form ref={form} onSubmit={sendEmail}>
                <label htmlFor="user_name">Name</label>
                <input type="text" name="user_name" required />
                <label htmlFor="user_email">Email</label>
                <input type="email" name="user_email" required />
                <label htmlFor="message">Message</label>
                <textarea name="message" required />
                <input type="submit" value="Send" />
              </form>
            </StyledContactForm>
          </div>
        </StyledText>
        <iframe
          src="https://my.spline.design/manwritingletter-2cad42b6a59498cd7fbe2cc513373af2/"
          title="letter"
          frameBorder="0"
          width="350px"
          height="350px"
        ></iframe>
      </div>
    </StyledAboutSection>
  );
};

export default Contact;
