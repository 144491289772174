import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 1100px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">Me, Myself & I</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I'm a full-stack software engineer and web developer with a passion for creating
              beautiful, functional, and scalable applications. I have 3 years of experience working
              with many technologies.
            </p>

            <p>
              I have a knack for problem-solving and a keen eye for detail, which has allowed me to
              deliver high-quality projects that meet and exceed client expectations. I'm always
              eager to learn new technologies and explore new ways of developing creative and
              innovative solutions for my clients.
            </p>

            <p>
              As a software engineer, I love the process of taking a complex problem and breaking it
              down into manageable parts. There's nothing more satisfying than seeing a solution
              come together and knowing that it will make a real difference for users.
            </p>

            <p>
              When I'm not coding away, I love to hacked spend time learning new things and experimenting
              with new technologies. I'm also a gamer, avid traveler, and blog writer.
            </p>
          </div>
        </StyledText>

        <iframe
          src="https://my.spline.design/ball-55e927f004ef6327b772451195f3cce9/"
          title="My Skills"
          frameBorder="0"
          width="450px"
          height="450px"
        ></iframe>
      </div>
    </StyledAboutSection>
  );
};

export default About;
